import { pdf } from '@react-pdf/renderer';
import FormTemplate from '../components/MultiFormViewer/FormTemplate';
import { APP_TYPE_APPLICATION_PROCESS } from '../pages/ProgramSetup/subPages/StagesAndForms/constants';

export function generateUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export const compareMultiLevelArray = (a, b) => {
	let isEqual = JSON.stringify(a) === JSON.stringify(b);
	return isEqual;
};

export function reOrderRows(orderArr, rows) {
	let orderedValues = orderArr?.map((e) => rows?.find((x) => x?.appId === e || x?.form_id === e));
	let unorderedValues = rows?.filter((e) => !orderArr?.includes(e?.appId || e?.form_id));

	orderedValues = orderedValues?.filter((value) => value !== undefined) || [];
	unorderedValues = unorderedValues?.filter((value) => value !== undefined) || [];

	return [...orderedValues, ...unorderedValues];
}

export function removeDuplicates(arr) {
	const unique = {};
	const result = [];

	for (const obj of arr) {
		if (!unique[obj.appId]) {
			result.push(obj);
			unique[obj.appId] = true;
		}
	}

	return result;
}

export function onlySubpageRows(arr, subPage) {
	const finalArray = arr?.filter((e) => e?.hierarchy?.includes(subPage));
	return finalArray;
}

export function is_within_range(masterStage, overrideDates) {
	if (overrideDates) {
		return true;
	}

	if (masterStage) {
		const currentDate = new Date();
		const startDate = new Date(masterStage.start_date);
		const endDate = new Date(masterStage.end_date);

		if (currentDate >= startDate && currentDate <= endDate) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}

export const checkOverflow = (e) => {
	const el = e?.target;
	var curOverflow = el.style.overflow;
	if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

	var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

	el.style.overflow = curOverflow;
	return isOverflowing;
};

export const hideIFrameContent = () => {
	const removedClasses = [
		'navbar-toolbar',
		'sidenav-drawer',
		'programs-title',
		'breadcrumbs-links-container',
		'individualprograms-box'
	];
	document.querySelector(`[data-testid=baseLayout-mainContent]`).style.marginTop = '0px';
	removedClasses?.forEach((id) => {
		const element = document?.querySelector(`[data-testid=${id}]`);

		if (element) {
			element.style.display = 'none';
		}
	});
};

export const setPencilIcon = (readOnly) => {
	const breadCrumbPencilIcon = document.getElementById('breadcrumbs-link-pencilIcon');
	if (!readOnly && breadCrumbPencilIcon) {
		breadCrumbPencilIcon.style.display = 'block';
	} else if (readOnly && breadCrumbPencilIcon?.style?.display === 'block') {
		breadCrumbPencilIcon.style.display = 'none';
	}
};

export const isDecisionChip = (value) => {
	const decisions = ['rec. award', 'on hold', 'denied'];
	value = value?.toLowerCase();
	return decisions.includes(value);
};

export const getIconColor = (status) => {
	//TODO: Fix the two defaults
	const colorMap = {
		Published: 'rgba(56, 142, 60, 1)',
		Draft: 'rgba(144, 164, 174, 1)',
		Closed: 'rgba(69, 90, 100, 1)',
		Archived: 'rgba(0, 0, 0, 1)',
		'Ready to Publish': 'rgba(156, 39, 176, 1)',
		Finalized: 'rgba(156, 39, 176, 1)',
		default: 'rgba(0, 0, 0, 0.54)',
		'Under Review': 'rgb(112, 112, 112,1)',
		'Not Started': 'rgba(144, 164, 174,1)',
		'Rec. Award': 'rgba(41, 182, 246, 1)',
		'In Progress': 'rgba(156, 39, 176, 1)',
		'In Progress (returned)': 'rgba(123, 31, 162, 1)',
		'In Progress (paused)': 'rgba(123, 31, 162, 1)',
		'In Progress (reopened)': 'rgba(156, 39, 176, 1)',
		'Draft (reopened)': 'rgba(123, 31, 163,1)',
		'Draft (paused)': 'rgba(123, 31, 162, 1)',
		'Draft (returned)': 'rgba(123, 31, 162, 1)',
		'Pending Decision': 'rgba(27, 93, 32, 1)',
		Submitted: 'rgba(56, 142, 60, 1)',
		Unassigned: 'rgba(252, 186, 3,1)',
		Completed: 'rgba(102, 187, 106, 1)',
		Denied: 'rgba(235, 64, 52,1)',
		White: 'rgba(255, 255, 255, 1)',
		default: 'rgba(144, 164, 174,1)'
	};
	return colorMap[status] || colorMap['default'];
};

export const appStagesCompletedAndAfter = [
	'Completed',
	'Rec. Award',
	'On Hold',
	'Pending Decision',
	'Denied'
];

export const getEcivisBlueColor = (alpha) => {
	if (!alpha) {
		return '#009DAC';
	} else {
		return `rgba(0, 157, 172, ${alpha})`;
	}
};

export const getEunaOrangeColor = (alpha) => {
	if (!alpha) {
		return '#FF6E14';
	} else {
		return `rgb(255, 110, 20, ${alpha})`;
	}
};

export const getOldBlueColor = (alpha) => {
	if (!alpha) {
		return '#1976D2';
	} else {
		return `rgba(25, 118, 210, ${alpha})`;
	}
};

export const getChipColor = (value) => {
	value = value?.toLowerCase();
	if (value?.includes('unassigned')) {
		return '#FBC02D';
	} else if (value?.includes('assigned')) {
		return '#E0F7FA';
	} else if (value === 'in progress' || value === 'draft') {
		return '#9C27B0';
	} else if (value?.includes('draft')) {
		return '#7B1FA3';
	} else if (value?.includes('in progress (paused)')) {
		return '#7B1FA2';
	} else if (value?.includes('in progress (returned)')) {
		return '#7B1FA2';
	} else if (value?.includes('in progress')) {
		return '#9C27B0';
	} else if (value?.includes('submitted')) {
		return '#388E3C';
	} else if (value?.includes('not started')) {
		return '#90A4AE';
	} else if (value?.includes('published')) {
		return '#4CAF50';
	} else if (value?.includes('completed')) {
		return '#66BB6A';
	} else if (value?.includes('under consideration')) {
		return '#1B5E20';
	} else if (value?.includes('pending decision')) {
		return '#1B5E20';
	} else if (value?.includes('rec. award')) {
		return '#29B6F6';
	} else if (value?.includes('on hold')) {
		return '#FF9800';
	} else if (value?.includes('denied')) {
		return '#E65100';
	} else if (value?.includes('finalized')) {
		return '#000000';
	} else if (value?.includes('sent')) {
		return '#E0F7FA';
	} else if (value?.includes('closed')) {
		return '#000000';
	} else if (value?.includes('status')) {
		return 'rgba(0, 0, 0, 0.38)';
	} else if (['invitation accepted', 'accepted'].includes(value)) {
		return '#4CAF50';
	} else if (['invitation rejected', 'rejected'].includes(value)) {
		return '#D32F2F';
	} else if (value?.includes('ready to publish')) {
		return 'rgba(156, 39, 176, 1)';
	} else {
		return '#009DAC';
	}
};

export const getChipColorPrograms = (value) => {
	value = value?.toLowerCase();
	if (value?.includes('draft')) {
		return '#90A4AE';
	} else if (value?.includes('published')) {
		return '#388E3C';
	} else if (value?.includes('closed')) {
		return '#616161';
	} else if (value?.includes('archived')) {
		return '#000000';
	} else if (value?.includes('finalized')) {
		return '#9C27B0';
	} else if (value?.includes('ready to publish')) {
		return 'rgba(156, 39, 176, 1)';
	} else {
		return '#707070';
	}
};

export const getChipTextColor = (value) => {
	value = value?.toLowerCase();
	if (['unassigned', 'assigned', 'sent'].includes(value)) {
		return 'black';
	} else if (value?.includes('archived')) {
		return '#90A4AE';
	} else {
		return 'white';
	}
};

export const getApplicationPageTitle = (props) => {
	const { appId, title, applicationStageID, reviewer } = props;

	if (appId?.includes('rst-')) {
		return `${title}`;
	}
	if (appId?.includes('arr-')) {
		return `${title} [${appId}] | ${reviewer}`;
	}
	if (appId?.includes('afa-')) {
		return `Application [${applicationStageID}] | ${title} [${appId}]`;
	}
	if (appId?.includes('rvf-')) {
		return `${title} | [${appId}]`;
	}
	if (appId?.includes('sst-')) {
		return `${title}`;
	} else {
		return `Application [${applicationStageID}]`;
	}
};

export function removeHttpVariations(string) {
	// Remove variations of 'https://'
	string = string.replace(/https:\/\/|https:\/\//g, '');

	// Remove variations of 'http://'
	string = string.replace(/http:\/\/|http:\/\//g, '');

	// Remove variations of 'http//'
	string = string.replace(/http\/\//g, '');

	return string;
}

export function getEntityPrefix({
	type,
	isProgram,
	isAdHoc,
	isBaseTemplate,
	isMaters,
	isInstance
}) {
	if (isProgram) return 'p-';

	if (isAdHoc) {
		switch (type) {
			case 'application-form':
				return 'aaft-';
			case 'review-form':
				return 'arft-';
		}
	}

	if (isBaseTemplate) {
		switch (type) {
			case 'Application Process':
			case 'application-process':
				return 'apt-';
			case 'Submission Stage':
			case 'submission-stage':
				return 'sst-';
			case 'Application Form':
			case 'application-form':
				return 'baft-';
			case 'Evaluation Stage':
			case 'evaluation-stage':
				return 'est-';
			case 'Review Form':
			case 'review-form':
				return 'brft-';
		}
	}

	if (isMaters) {
		switch (type) {
			case 'application-process':
			case 'Application Process':
				return 'apm-';
			case 'submission-stage':
			case 'Submission Stage':
				return 'ssm-';
			case 'application-form':
			case 'Application Form':
				return 'afm-';
			case 'Evaluation Stage':
			case 'evaluation-stage':
				return 'esm-';
			case 'Review Form':
			case 'review-form':
				return 'rfm-';
		}
	}

	if (isInstance) {
		switch (type) {
			case 'application-process':
				return 'api-';
			case 'submission-stage':
				return 'ssi-';
			case 'application-form':
				return 'afi-';
			case 'evaluation-stage':
				return 'esi-';
			case 'review-form':
				return 'rfi-';
		}
	}
}

export const calculateMasterFormCompletion = (arr) => {
	const finalArray = arr?.map((e) => {
		if (e?.type === 'Review Form(s)') {
			let totalForms = 0;
			let completedForms = 0;
			let notStartedForms = 0;

			arr?.forEach((x) => {
				if (x?.hierarchy?.includes(e?.appId) && x?.type !== 'Review Form(s)') {
					totalForms++;
					x?.status === 'Completed' && completedForms++;
				}
			});

			e['status'] = totalForms === completedForms ? 'Completed' : e?.status;
			e['status'] = totalForms === notStartedForms ? 'Not Started' : e?.status;
			e['status'] = totalForms === 0 ? 'Unassigned' : e?.status;
			e['completion'] = `${
				completedForms > 0 ? Math.floor((completedForms / totalForms) * 100) : 0
			}%`;
			return e;
		} else {
			return e;
		}
	});
	return finalArray;
};

export function getUniqueValues(arrays) {
	const uniqueValues = new Set();

	arrays.forEach((array) => {
		array.forEach((value) => {
			uniqueValues.add(value);
		});
	});

	return Array.from(uniqueValues);
}

export function getUniqueValuesFromObject(object) {
	const allArrays = Object.values(object).flat();
	const uniqueArray = [...new Set(allArrays)];
	return uniqueArray;
}

export const checkProcessValidDates = (setupRows) => {
	const invalidEntities = [];
	const requiresDate = [APP_TYPE_APPLICATION_PROCESS];

	setupRows?.forEach((e) => {
		if (requiresDate.includes(e?.type)) {
			if (!e?.startDate || !e?.endDate) {
				invalidEntities?.push(e?.uniqueIdentifier);
			}
		}
	});

	return invalidEntities;
};

export const validateRequiredFields = (formSchema, formAnswers) => {
	if (!formSchema) {
		return [];
	}

	let errors = [];

	for (const formItem of formSchema) {
		const matchingAnswer = formAnswers?.find((answer) => answer.field === formItem.field_id);
		if (matchingAnswer && formItem?.required) {
			if (formItem.element == 'Signature') {
				if (matchingAnswer?.value?.length === 0) {
					errors.push({
						id: formItem.field_id,
						field_name: formItem.field_name,
						label: formItem.label,
						error: `is required`
					});
				}
			} else {
				switch (formItem.key) {
					case 'MUI_LinkInput':
						if (matchingAnswer?.value[0]?.length === 0 || matchingAnswer?.value[1]?.length === 0) {
							errors.push({
								id: formItem.field_id,
								field_name: `${formItem.field_name}-title`,
								label: formItem.label,
								error: `is required`
							});
						}
						break;
					case 'MUI_StartEndDate':
						if (matchingAnswer?.value[0]?.length === 0 || matchingAnswer?.value[1]?.length === 0) {
							errors.push({
								id: formItem.field_id,
								field_name: `${formItem.field_name}-start`,
								label: formItem.text,
								error: `is required`
							});
						}
						break;
					case 'MUI_FileUpload':
					case 'MUI_Rating':
					case 'StatePicker':
					case 'MUI_Dropdown':
						if (matchingAnswer?.value?.length === undefined || matchingAnswer?.value.length === 0) {
							errors.push({
								id: formItem.field_id,
								field_name: `${formItem.field_name}`,
								label: formItem.label,
								error: `is required`
							});
						}
						break;
					case 'MUI_RadioButton':
					case 'MUI_DatePicker':
					case 'MUI_Checkbox':
					case 'MUI_NumberInput':
					case 'MUI_TextArea':
					case 'MUI_Input':
					case 'MUI_Email':
					case 'MUI_PhoneNumber':
						if (matchingAnswer?.value?.length === 0) {
							errors.push({
								id: formItem.field_id,
								field_name: `${formItem.field_name}`,
								label: formItem.label,
								error: `is required`
							});
						}
						break;
					default:
						break;
				}
			}
		}
	}
	return errors;
};

export const validateDate = (date) => {
	const dateParts = date.split('/');

	if (dateParts.length !== 3) {
		return false;
	}
	const [month, day, year] = dateParts;
	const dateObj = new Date(year, month - 1, day);
	return (
		dateObj.getFullYear() == year && dateObj.getMonth() == month - 1 && dateObj.getDate() == day
	);
};

export const areRequiredFieldsTested = (formSchema, formAnswers) => {
	for (const formItem of formSchema) {
		const matchingAnswer = formAnswers.find((answer) => answer.name === formItem.field_name);
		if (matchingAnswer && formItem.required) {
			if (formItem.element == 'Signature') {
				if (typeof matchingAnswer.value === 'undefined') return false;
				if (matchingAnswer.value.length === 0) return false;
			} else {
				switch (formItem.key) {
					case 'StatePicker':
					case 'MUI_Dropdown':
					case 'MUI_FileUpload':
						if (typeof matchingAnswer.value === 'undefined') return false;
						if (matchingAnswer.value === null) return false;
						if (matchingAnswer.value.length === 0) return false;
						if (matchingAnswer.value == '') return false;
						break;
					case 'MUI_LinkInput':
					case 'MUI_StartEndDate':
						if (matchingAnswer.value[0].length === 0 || matchingAnswer.value[1].length === 0)
							return false;
						break;
					case 'MUI_RadioButton':
					case 'MUI_DatePicker':
					case 'MUI_Rating':
					case 'MUI_Checkbox':
					case 'MUI_NumberInput':
					case 'MUI_TextArea':
					case 'MUI_Input':
					case 'MUI_Email':
					case 'MUI_PhoneNumber':
						if (typeof matchingAnswer.value === 'undefined') return false;
						if (matchingAnswer.value.length === 0) return false;
						break;
					default:
						break;
				}
			}
		}
	}
	return true;
};

export const createAndDownloadFile = (blob, fileName) => {
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');

	a.href = url;
	a.download = fileName;

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export const exportFormToPDF = async (apiFormData, applicationStatus, applicationID) => {
	const form = apiFormData.form;

	const formData = {
		programTitle: form.program.project_title,
		programID: form.program.id,
		label: form.label,
		applicationID,
		instructions: form.instructions
	};

	const rows = [];
	const usedFieldIDs = [];

	apiFormData.form_schema.forEach((schema) => {
		if (schema.group_name === 'Layout') {
			if (schema.child_items) {
				schema.child_items.forEach((child_schema_id) => {
					const child_schema = apiFormData.form_schema.find(
						(inner_schema) => inner_schema.field_id.toUpperCase() === child_schema_id
					);

					if (child_schema) {
						const fieldValue = apiFormData.field_values.find(
							(field) => field.field.toUpperCase() === child_schema_id
						);

						if (fieldValue && !usedFieldIDs.find((fieldID) => fieldID === fieldValue.field)) {
							let value = fieldValue.value;

							if (
								child_schema.label === 'Application' &&
								child_schema.custom_options &&
								child_schema.custom_options.includes('not-editable') &&
								child_schema.custom_options.includes('core-application')
							) {
								value = `${applicationStatus} [api-${applicationID}]`;
							}
							rows.push({
								label: child_schema.label || child_schema.text,
								isSectionLabel: false,
								required: !!child_schema.required,
								value
							});

							usedFieldIDs.push(fieldValue.field);
						}
					}
				});
			} else if (schema.field_name.includes('groupingStart')) {
				rows.push({
					label: schema.label,
					isSectionLabel: true,
					required: false,
					value: ''
				});
			}
		} else if (schema.group_name === 'Controls') {
			const fieldValue = apiFormData.field_values.find((field) => field.field === schema.field_id);

			if (fieldValue && !usedFieldIDs.find((fieldID) => fieldID === fieldValue.field)) {
				let value = '';

				if (typeof fieldValue.value === 'string' && fieldValue.value.length > 0) {
					if (schema.key.includes('Dropdown')) {
						value = schema.options.find((option) => option.key === fieldValue.value).text;
					} else {
						value = fieldValue.value;
					}
				} else if (Array.isArray(fieldValue.value)) {
					if (schema.key.includes('RadioButton') || schema.key.includes('Checkbox')) {
						value = fieldValue.value
							.map(
								(selectedOption) =>
									schema.options.find((option) => option.key === selectedOption).text
							)
							.join(' - ');
					} else {
						value = fieldValue.value.join(' - ');
					}
				}

				rows.push({
					label: schema.label || schema.text,
					isSectionLabel: false,
					required: !!schema.required,
					value
				});

				usedFieldIDs.push(fieldValue.field);
			}
		}
	});

	const blob = await pdf(<FormTemplate formData={formData} rows={rows} />).toBlob();
	const fileName = `${form.label}.pdf`;

	createAndDownloadFile(blob, fileName);
};

const stringLenMax = 17; // default value if nothing passed

export const stringLengthMax = stringLenMax;

export const truncateString = (data, maxLength = stringLenMax) => {
	return data ? (data.length > maxLength ? data.slice(0, maxLength - 1) + '...' : data) : '';
};

// https://stackoverflow.com/a/38699214
export const setCookie = (name, value, days = 7, path = '/') => {
	const expires = new Date(Date.now() + days * 864e5).toUTCString();
	document.cookie =
		name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path;
};

export const getCookie = (name) => {
	return document.cookie.split('; ').reduce((accumulator, currentValue) => {
		const parts = currentValue.split('=');
		return parts[0] === name ? decodeURIComponent(parts[1]) : accumulator;
	}, '');
};
export const deleteCookie = (name, path) => {
	setCookie(name, '', -1, path);
};
